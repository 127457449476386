<template lang="">
  <AppCollapse type="border-info card">
    <AppCollapseItem
      title
      class-header="card-header bg-light-info"
      class="border-info"
    >
      <template #header>
        <span class="text-dark font-weight-bolder">
          {{ $t('agency.auditLog') }}
        </span>
      </template>
      <b-table-lite
        bordered
        responsive
        :sticky-header="true"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        small
        class="mb-1 rounded"
        thead-class="text-dark font-weight-bolder text-nowrap"
        :items="auditLogs"
        :fields="tableColumns"
      >
        <template
          v-for="(column) in tableColumns"
          v-slot:[`head(${column})`]="data"
        >
          <span
            :key="column"
            class="text-dark"
          >
            {{ $t(`agency.${data.column}`) }}
          </span>
        </template>
        <template #cell(no)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(paxUpdated)="{ item }">
          <div class="fw-700 text-nowrap">
            {{ item?.modifiedBy?.firstName }} {{ item?.modifiedBy?.lastName }}
          </div>
        </template>
        <template #cell(action)="{ item }">
          <div>
            {{ item.action }}
          </div>
        </template>
        <template #cell(updatedData)="{ item }">
          <div v-if="item.newValue">
            <template v-if="$te(item.newValue)">
              {{ $te(item.newValue) ? $t(item.newValue) : item.newValue }}
            </template>
            <template v-else-if="!item.action.includes('BALANCE')">
              <div>
                <div
                  v-for="(newVal, key) in JSON.parse(item.newValue)"
                  :key="key"
                >
                  <template v-if="checkTypeValueAuditLog(newVal)">
                    <div
                      v-for="(val, key2) in newVal"
                      :key="key2"
                    >
                      {{ key2 }}: {{ val }}
                    </div>
                  </template>
                  <span v-else>
                    {{ key }}: {{ newVal }}
                  </span>
                </div>
              </div>
            </template>

            <template v-else-if="Number(item.newValue)">
              <span>{{ formatCurrency(item.newValue) }}</span>
            </template>
            <template v-else>
              <span>{{ item.newValue }}</span>
            </template>
          </div>
        </template>
        <template #cell(updatedAt)="{ item }">
          <div>
            {{ convertISODateTime(item.createdAt).dateTime }}
          </div>
        </template>
      </b-table-lite>
    </AppCollapseItem>
  </AppCollapse>
</template>
<script>
import { BTableLite } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import { formatCurrency, convertISODateTime } from '@core/utils/filter'

export default {
  components: {

    BTableLite,
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  props: {
    auditLogs: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const tableColumns = ref(['no', 'paxUpdated', 'action', 'updatedData', 'updatedAt'])

    const checkTypeValueAuditLog = val => typeof val === 'object'
    return {
      tableColumns, formatCurrency, convertISODateTime, checkTypeValueAuditLog,
    }
  },
}
</script>
<style lang="">

</style>
